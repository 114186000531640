import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { bounceInLeftAnimation, bounceInLeftOnEnterAnimation,
         bounceInOnEnterAnimation,
         bounceInRightOnEnterAnimation,
         fadeInAnimation,
         fadeInOnEnterAnimation,
         fadeOutOnLeaveAnimation,
         flipInXAnimation,
         flipInXOnEnterAnimation,
         flipInYAnimation,
         flipInYOnEnterAnimation,
         rubberBandOnEnterAnimation,
         swingAnimation,
         tadaAnimation,
         fadeInDownOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fadeInOnEnterAnimation({delay: 500}),
    fadeOutOnLeaveAnimation(),
    rubberBandOnEnterAnimation(),
    swingAnimation(),
    tadaAnimation(),
    bounceInOnEnterAnimation({delay:800}),
    bounceInLeftOnEnterAnimation({delay:1000}),
    bounceInRightOnEnterAnimation({delay:1000}),
    flipInXOnEnterAnimation({ delay: 200 }),
    flipInYOnEnterAnimation({ delay: 200 }),
    bounceInLeftAnimation({anchor: 'bil', delay: 500}),
    fadeInAnimation({anchor: 'fi1000', delay: 1000}),
    flipInYAnimation(),
    flipInXAnimation(),
    fadeInDownOnEnterAnimation()
  ]
})



export class AppComponent implements OnInit {
  title = 'biWorkWeb';
  navBarShadowClass= false;
  numberOfSlides= 3;
  slide= 1
  swingButton= false;
  showDescriptionSection= false;
  showCiteSection= false;
  showAboutUsSection= false;
  showFormSection= false;
  showEmptyDiv= true;
  form: FormGroup;

  sendingMessage= false;
  messageSended= false;

  constructor(private fb: FormBuilder, private http: HttpClient ){}


  @HostListener('window:scroll', ['$event'])  onScroll($event:Event):void {
    let scrollPosition= window.scrollY;
    scrollPosition > 0 ? this.navBarShadowClass = true : this.navBarShadowClass = false;

    let mainSection= document.getElementById('main-section');
    if (mainSection && !this.showDescriptionSection) {
      let result=   mainSection.getBoundingClientRect().bottom  - window.innerHeight;
      if (result < 0) {
        this.showDescriptionSection= true;
      }
    }

    let descriptionSection= document.getElementById('description-section');
    if (descriptionSection && !this.showCiteSection) {
      let result=   descriptionSection.getBoundingClientRect().bottom - window.innerHeight;
      if (result < 0) {
        this.showCiteSection= true;
      }
    }

    let citeSection= document.getElementById('cite-section');
    if (citeSection && !this.showAboutUsSection) {
      let result=   citeSection.getBoundingClientRect().bottom - window.innerHeight;
      if (result < 0) {
        this.showAboutUsSection= true;
      }
    }

    let aboutUsSection= document.getElementById('about-us');
    if (aboutUsSection && !this.showFormSection) {
      let result= aboutUsSection.getBoundingClientRect().bottom - window.innerHeight;
      if (result < 0) {
        this.showFormSection= true;
      }
    }
  };

  scrollToElement($element): void {
    if($element){
      let elementPosition= $element.offsetTop - 120;
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      })
    }
  }

  changeSlide(slide: number){
    setInterval(()=> {
      slide ++;
      if (slide > this.numberOfSlides) slide = 1;
      this.slide = slide;
    }, 3000)
  };

  /* FORM`S METHODS  */
  send($formValue){
    this.sendingMessage = true;
    //this.http.post<string>('http://biworkweb-env.eba-upssa4zs.sa-east-1.elasticbeanstalk.com/consulta', $formValue)
    this.http.post<string>('https://biworkweb.herokuapp.com/consulta', $formValue)
    .subscribe(
      () => {
        this.sendingMessage= false,
        this.messageSended= true,
        this.form.reset();
      },
      err => { console.log(err), this.sendingMessage= false }
    )
  }

  fillForm(){
    this.form.controls.consulta.setValue('Estimados, quisiera solicitar la instalación de una versión de prueba del sistema.')
  }

  disableEnviar(){
    return (
      this.form.invalid ||
      this.sendingMessage
      )
  }

  /* END FORM´S METHODS */

  ngOnInit() {
    this.changeSlide(this.slide);
    setInterval(()=>{ this.swingButton= !this.swingButton }, 10000);

    this.form= this.fb.group({
      nombre: ['', Validators.required],
      email:  ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.pattern('[- +()0-9]{6,}')],
      empresa: [''],
      posicion: [''],
      consulta: ['', Validators.required]
    });
  }



}
