<!-- FIXED ELEMENTS -->
<a href="https://wa.me/543415460297?text=Quisiera%20información%20sobre%20el%20sistema" class="whatsapp" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>

<!-- END FIXED ELEMENTS -->

<div class= 'nav-bar' [ngClass]="{'mat-elevation-z6': navBarShadowClass === true, 'navBarShadow': navBarShadowClass == true}" fxLayout= 'row'   fxLayoutAlign="space-between center">
  <div fxLayout='column'>
    <img [@rubberBandOnEnter] src="../assets/img/biwork3.png" alt="">
    <p>Somos lo que hacemos repetidamente</p>
  </div>
  <button [@tada]='swingButton' mat-stroked-button (click)="scrollToElement(formSection)">CONTÁCTENOS</button>
</div>

<div id='main-section' class='main-section' fxLayout='column' fxLayoutAlign='start center'>
   <div class='content' fxLayout='column' fxLayoutAlign='start center'>
    <h1 [@fadeInOnEnter] >Software LIMS de gestión para laboratorios de servicios y de calidad industrial  </h1>
    <h3 [@fadeInOnEnter] >Digitalice sus registros, simplifique tareas, mejore su productividad y control</h3>
    <h4 [@fadeInOnEnter]>
      BIWORK le propone como solución a su necesidad un software LIMS (Laboratory Information Management System) <br> de uso simple
      cuyo resultados son la trazabilidad, la despapelización, la automatización, <br>
      la optimización, la eficiencia procedimental y el cumplimiento de normativas.
    </h4>

    <div class='main-data' fxLayout='row wrap' fxLayoutAlign='center center'>
      <div [@bounceInOnEnter] class='img-container' fxFlex='50'>
        <img src="../assets/img/macbook-562498.png" alt="Imagen computadora">
      </div>

      <div [@bounceInLeftOnEnter] fxLayout='column' fxFlex='40' fxLayoutAlign='space-around center' fxHide.lt-md>
        <div class='feature-description'>
          <h3>ORGANICE SUS PROCESOS DE MUESTRAS Y ANALISIS</h3>
          <p>Sepa rápidamente el estado de sus procesos desde que ingresa la muestra hasta su egreso con informe. </p>
        </div>
        <div class='feature-description'>
          <h3>ALERTAS DE VENCIMIENTO</h3>
          <p>Esté informado sobre los vencimientos de calibración de sus equipos y material volumétrico y manténgalos siempre al día.</p>
        </div>
        <div class='feature-description'>
          <h3>AUTOMATIZACIÓN Y VALIDACIONES</h3>
          <p>Cálculos automáticos y validaciones permiten evitar errores y aumentar la productividad evitando la perdida de tiempo en búsqueda de información de certificados de calidad. </p>
        </div>
        <div class='feature-description'>
          <h3>ISO 17025</h3>
          <p>Otorga soporte para orientar su laboratorio al cumplimiento de la norma de calidad ISO 17025.</p>
        </div>
      </div>
    </div>

    <div class='slider-container' fxFlex fxHide.gt-sm>
        <ul class='slider'>
          <li *ngIf="slide == 1" [@fadeInOnEnter] [@fadeOutOnLeave]  class='feature-description slide'>
            <h3>ORGANICE SU STOCK DE DROGAS Y SOLUCIONES</h3>
            <p>Sepa rápidamente con que drogas cuenta, evite compras innecesarias y vencimientos sin uso. </p>
          </li>
          <li *ngIf="slide == 2" [@fadeInOnEnter] [@fadeOutOnLeave]  class='feature-description slide'>
            <h3>ALERTAS DE VENCIMIENTO</h3>
            <p>Esté informado sobre los vencimientos de calibración de sus equipos y material volumétrico y manténgalos siempre al día.</p>
          </li>
          <li *ngIf="slide == 3"  [@fadeInOnEnter] [@fadeOutOnLeave]  class='feature-description slide'>
            <h3>AUTOMATIZACIÓN Y VALIDACIONES</h3>
            <p>Cálculos automáticos y validaciones permiten evitar errores y aumentar la productividad evitando la perdida de tiempo en búsqueda de información de certificados de calidad. </p>
          </li>
          <li *ngIf="slide == 4"  [@fadeInOnEnter] [@fadeOutOnLeave]  class='feature-description slide'>
            <h3>ISO 17025</h3>
            <p>Logre soporte para orientar su laboratorio al cumplimiento de la norma de calidad ISO 17025.</p>
          </li>
        </ul>
    </div>
  </div>
</div>

<div [ngClass]="{'hidden': !showDescriptionSection, 'visible': showDescriptionSection}" id='description-section' class='description-section' fxLayout='column' fxLayoutGap='25px' #descriptionSection >
  <div fxLayout="row wrap" fxLayoutGap='15px' fxLayoutGap.lt-md='0px'>

    <div [@flipInX]='showDescriptionSection' class='image-container' fxLayout='row' fxLayoutAlign='center center' fxFlex='38' fxFlex.lt-md='100' >
      <img fxFlex fxFlexAlign="center" src="../assets/img/4x/Asset 1@4x.png" alt="Ilustración laboratorio">
  </div>

  <div [@flipInY]='showDescriptionSection' class='text-container blue' fxLayout='column' fxLayoutAlign='center center' fxFlex='58' fxFlex.lt-md='100'>
    <h1>HABLAMOS SU LENGUAJE</h1>
    <h2>Más de 10 años de experiencia dentro de la industria de los laboratorios nos permite conocer el día a día de la operatividad. Conocemos sus necesidades por dentro. Por ello le brindamos soluciones informáticas simples y asequibles para que gane eficiencia y reduzca costos.</h2>
  </div>
</div>

<div fxLayout="row wrap-reverse" fxLayoutGap='15px' fxLayoutGap.lt-md='0px'>
  <div [@bil]='showDescriptionSection' class='text-container orange' fxLayout='column' fxLayoutAlign='center center' fxFlex='58' fxFlex.lt-md='100'>
    <h1>SOLUCIONES A MEDIDA</h1>
    <h2>Ajustamos el sistema a sus procesos.</h2>
    <h2>Desarrollamos soluciones personalizadas</h2>
  </div>

  <div [@fi1000]='showDescriptionSection' class='image-container orange'  fxLayout='row' fxLayoutAlign='center center' fxFlex='38' fxFlex.lt-md='100' >
    <img fxFlex fxFlexAlign="center" src="../assets/img/4x/Artboard 1@4x.png" alt="Ilustración laboratorio">
  </div>
</div>
</div>



<div [@flipInY]='showCiteSection' [ngClass]="{'hidden': !showCiteSection, 'visible': showCiteSection}" id='cite-section' class="cite-section" fxLayout='column' fxLayoutAlign='center center'>
    <div fxLayout='column'>
      <h2>« Analizamos sus procesos y desarrollamos sistemas de automatización para laboratorios»</h2>
    </div>
</div>

<div [@flipInY]='showAboutUsSection' [ngClass]="{'hidden': !showAboutUsSection, 'visible': showAboutUsSection}" id='about-us' class="aboutUs-section" fxLayout='column' fxLayoutAlign='center center'>
  <h1>Nosotros</h1>
  <div fxLayout='row wrap' fxLayoutAlign='center center'>
    <img src="../assets/img/grupo-trabajo.png" alt="">
    <h2>
      BIWORK es una compañía startup de base tecnológica cuyos fundadores tienen más de 10 años de experiencia en la industria de los laboratorios.
      Ese conocimiento está orientado a desarrollar sistemas informáticos para la automatización y digitalización de procesos que se ajusten a las
      necesidades de cada empresa con soluciones simples y asequibles, con una clara misión: reducción de costos y mejora de la rentabilidad del cliente.
    </h2>
  </div>
</div>

<div [ngClass]="{'hidden': !showFormSection, 'visible': showFormSection}" id='form-section' class="form-section" fxLayout='row' fxLayoutAlign='center center' #formSection>
  <mat-card [@bil]='showFormSection' fxFlex='70' fxFlex.lt-md='97'>
    <mat-card-title>Envíenos su consulta</mat-card-title>
    <mat-card-content>
      <form [formGroup]='form' fxLayout='column' (ngSubmit)='send(form.value)' #documentEditForm='ngForm'>
        <div fxLayout='row wrap' fxLayoutGap='10px'>
          <mat-form-field fxFlex='30' appearance="outline" fxFlex.lt-sm='100'>
            <mat-label>Nombre</mat-label>
            <input formControlName='nombre' matInput placeholder="Ingrese su nombre...">
            <mat-error *ngIf="form.controls.nombre.invalid">Campo requerido</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex='30' appearance="outline" fxFlex.lt-sm='100'>
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Ingrese su email...">
            <mat-error *ngIf="form.controls.email.hasError('required')">Campo requerido</mat-error>
            <mat-error *ngIf="form.controls.email.hasError('email')">Email inválido</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex='30' appearance="outline" fxFlex.lt-sm='100'>
            <mat-label>Teléfono</mat-label>
            <input formControlName="telefono" matInput placeholder="Ingrese su número de teléfono...">
            <mat-error *ngIf="form.controls.email.hasError('required')">Número inválido</mat-error>
            <mat-hint>+54 341 155123456</mat-hint>
          </mat-form-field>
        </div>
        <div fxLayout='row wrap' fxLayoutGap='10px'>
          <mat-form-field appearance="outline" fxFlex='30' fxFlex.lt-sm='100'>
            <mat-label>Empresa</mat-label>
            <input formControlName="empresa" matInput placeholder="Nombre de su empresa...">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex='40' fxFlex.lt-sm='100'>
            <mat-label>Posición</mat-label>
            <input formControlName="posicion" matInput placeholder="La posición que ocupa en su empresa...">
          </mat-form-field>
        </div>
          <mat-form-field class="example-full-width">
          <mat-label>Consulta</mat-label>
          <textarea formControlName="consulta" matInput rows='10' placeholder="¿En qué podemos ayudarlo?"></textarea>
          <mat-error *ngIf="form.controls.consulta.invalid">Campo Requerido</mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <div *ngIf='messageSended' class='info-message'>Consulta envíada. A la brevedad nos comunicaremos con usted.</div>
    <mat-card-actions fxLayout='row' fxLayoutAlign='center center'>
      <button mat-raised-button color='primary' (click)="documentEditForm.ngSubmit.emit()" [disabled]='disableEnviar()'>ENVIAR</button>
      <mat-spinner *ngIf='sendingMessage' [diameter]="30"></mat-spinner>
    </mat-card-actions>
  </mat-card>
</div>

<div [ngClass]="{'hidden': !showFormSection, 'visible': showFormSection}" class="footer-section" fxLayout='row' fxLayoutAlign='center center'>
  <div class='contact-info' fxLayout='row ' fxFlex='100' fxLayoutAlign='center center'>
    <div fxFlex='50' id='address-container' fxLayout='column' fxLayoutAlign='center center'>
      <p>Oroño 415</p>
      <p>Rosario | Argentina</p>
      <a href='http://biwork.com.ar'>www.biwork.com.ar</a>
    </div>
    <div fxLayout='column' fxFlex='50'>
      <div id='comercial-container'>
        <p>Departamento Comercial</p>
        <p>+54 341 5460297</p>
        <p>esalvana@biwork.com.ar</p>
      </div>
      <div>
        <p>Departamento Técnico</p>
        <p>+54 341 6563658</p>
        <p>jsalvana@biwork.com.ar</p>
      </div>

    </div>
  </div>
</div>

<div [ngClass]="{'hidden': !showFormSection, 'visible': showFormSection}" class="atribuciones-section" fxLayout='row' fxLayoutAlign='end center'>
  <a href='https://www.freepik.es/vectores/libro' target='_blank'>Atribución 1</a>
</div>
<div *ngIf='showEmptyDiv' class='empty-div'>
algo
</div>
